<template>
  <div class="dialog-add-account" v-loading="loading">
    <div class="dialog-add-account__title">
     {{$t('business_account_client.title')}}
    </div>

      <div class="dialog-add-clients">
        <div class="dialog-add-account__form-title">
          {{ !currentNewUser.accountId ? $t('business_account_client.vk_user') : $t('business_account_client.vk_current_user') }}
        </div>
        <div  class="dialog-add-account__form-block">
          <div v-if="currentNewUser" class="dialog-add-clients__account">
            {{ currentNewUser.account_name || currentNewUser.accountName }}
          </div>
          
        </div>

        <div class="dialog-add-account__form-title">
          {{ !currentNewUser.accountId ? $t('business_account_client.list_of_classes') : $t('business_account_client.new_user') }}

          <div v-if="availableCabinetsList && availableCabinetsList.length" class="dialog-add-clients__check-action">
            <span v-show="availableCabinetsList.length !== accounts.length" @click="checkAction('add')">{{$t('business_account_client.choose_all')}}</span>
            <span v-show="availableCabinetsList.length === accounts.length" @click="checkAction('remove')">{{$t('business_account_client.take_it_all')}}</span>
          </div>
        </div>

        <div
          v-if="currentNewUser.accountId"
          class="dialog-add-account__form-block form-input account-card__popup-input"
          :class="
          [
            {'form-input--full' : accountName},
          ]"
        >
          <input v-model="accountName" type="text">
          <span class="form-input__label">
           {{$t('link')}}
          </span>
        </div>

        <div v-else-if="availableCabinetsList && availableCabinetsList.length" class="dialog-add-account__form-block dialog-add-clients__list">
          <div v-for="(item, index) in availableCabinetsList" :key="index" class="dialog-add-clients__client" :class="{'is-higlited': item.account_id}">
            <label class="input__item-checkbox">
              <input
                type="checkbox"
                :value="item"
                v-model="accounts"
              >
              <span class="input__item-checkbox__check">
                <img src="@/assets/icons/icon-done.svg">
              </span>
              <span class="input__item-checkbox__info">
                <span class="input__item-checkbox__title">
                  {{ item.client_username }}
                </span>
                <span v-if="item.name" class="dialog-add-clients__client-name">
                  {{ item.name }}
                </span>
              </span>
            </label>
          </div>
        </div>
        <ButtonElement 
          :text="!currentNewUser.accountId ? $t('button_props.append') : $t('button_props.save')"
          @click-button="sendData"
        />
      </div>
  </div>
</template>

<script>
import ButtonElement from '../../components/elements/ButtonElement'

export default {
  name: 'BusinessAccountClients',
  components: {
    ButtonElement
  },
  data() {
    return {
      loading: false,
      accountName: '',
      accounts: [],
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    user() {
      return this.$store.state.Auth.user
    },
    role() {
      return this.$store.state.Auth.role
    },
    availableCabinetsList() {
      return this.$store.state.Ad.observersAccounts
    },
    currentNewUser() {
      return this.$store.state.Ad.currentNewUser
    }
  },
  mounted() {
    if (this.availableCabinetsList && this.availableCabinetsList.length) {
        this.availableCabinetsList.forEach(el => {
        this.accounts.push(el)
      })
    }
  },
  beforeDestroy() {
    this.accounts = []
    this.$store.commit('Ad/setData', { label: 'observersAccounts', data: null })
    this.$store.commit('Ad/setData', { label: 'currentNewUser', data: null })
  },
  methods: {
    closeDialog() {
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
    },
    sendData() {
      if(!this.currentNewUser.accountId) {
        const clients = []
        const accounts  = []
        if (this.accounts.length) {
          this.accounts.forEach(el => {
            if (el.account_id) {
              accounts.push(el.account_id)
            } else {
              clients.push(el.client_id)
            }
          });
          this.addAccountAction(clients, accounts)
        } else if (this.role === 'manager'){
          this.addAccountAction(clients, accounts)
        } else {
          this.$notify({
            message: this.$t('business_account_client.message.choose_class'),
            type: 'error'
          });
        }
      } else {
        if (!this.accountName) {
          this.$notify({
            message: this.$t('business_account_client.message.choose_link'),
            type: 'error'
          });
          return
        }
        if (!this.accountName.startsWith('http')) {
          this.$notify({
            message: this.$t('business_account_client.message.error_link'),
            type: 'error'
          });
          return
        }
        this.changeAccountName()
      }
    },
    async addAccountAction(clients, accounts) {
      this.loading = true
      const data = {...this.currentNewUser}
      data.clients = clients
      data.accounts = accounts
      await this.$store.dispatch('Ad/addAccount', data)
        .then(() => {
          this.$store.commit('Ad/setData', { label: 'accountAddedSuccess', data: true })
          this.$notify({
            message: this.$t('business_account_client.message.add'),
            type: 'success'
          });
        })
        .catch(() => {
          this.$notify({
            message: this.$t('business_account_client.error'),
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false
          this.closeDialog()
        })
    },
    async changeAccountName() {
      this.loading = true
      await this.$store.dispatch('Ad/changeAccountName', {id: this.currentNewUser.accountId, data: {account_name: this.accountName}})
        .then(() => {
          this.$store.commit('Ad/setData', { label: 'accountAddedSuccess', data: true })
          this.$notify({
            message: this.$t('business_account_client.message.profile_link'),
            type: 'success'
          });
        })
        .catch(() => {
          this.$notify({
            message: this.$t('business_account_client.message.error'),
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false
          this.closeDialog()
        })
    },
    checkAction(action) {
      if (action === 'add') {
        this.accounts = []
        this.availableCabinetsList.forEach(el => {
          this.accounts.push(el)
        })
      } else {
        this.accounts = []
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.dialog-add-clients {
  margin-top: 3.2rem;

  .dialog-add-account__form-block {
    margin-bottom: 3.2rem;
  }

  &__account {
    font-size: 1.6rem;
    color: #333333;
    font-weight: 400;
  }

  &__client {
    .input__item-checkbox {
      padding: 1.6rem 0;
      border-bottom: 1px solid #EDEFF3;
      cursor: pointer;
      align-items: flex-start;
    }

    .input__item-checkbox__title {
      color: #333333;
    }

    &-name {
      color: #7F858D;
      margin-top: .65rem;
    }

    &.is-higlited {
      background: #FBFCFF;
    }
  }

  &__check-action {
    font-size: 1.6rem;
    color: #395FE4;
    position: relative;
    top: 1rem;
    cursor: pointer;
  }

  &__list {
    max-height: 33rem;
    overflow: auto;
  }

  .button {
    width: 100%;
    height: 5rem;
    font-weight: 700;
  }
}

.el-dialog {
  &__body {
    padding: 30px;
  }

  @include below(769px) {
    width: 100%;
    margin-top: 0!important;
    margin-bottom: 0;
  }

  @include below(577px) {

    &__body {
      padding: 16px;
      word-break: break-word;
    }

    &__headerbtn {
      top: 16px;
      right: 16px;
    }
  }
}
</style>
